.margin-right-31 {
	margin-right: 31px;
}

.card-colour-red {
	border-left: .25rem solid red;
}

.card-colour-green {
	border-left: .25rem solid green;
}

.card-title {
	color: black;
}

.card-subtitle {
	color: #636363 !important;
}

.card-text {
	color: black;
}

.card a {
	color: #0068d6 !important;
	font-weight: 500;
}

.no-text .sidebar-items {
	color: white;
}

@media (max-width: 575.98px) {
	.logo-img {
		float: right;
	}
}

